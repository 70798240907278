<template>
    <div class="user-nav">
        <div class="brand"><img src="/images/logo.svg" height="50"></div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.user-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: $themeColor1;
    border-bottom: 1px solid $borderColor;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr) auto;
    align-items: center;
    padding: 0 10px;
}
</style>