<template>
  <div class="user-nav" @mouseleave="selectedMenu = null">
    <div class="content">
      <div style="display: flex; gap: 50px; align-items: center">
        <div class="brand">
          <router-link to="/"
            ><img src="/images/invoice-logo.svg" height="34"
          /></router-link>
        </div>

        <div class="nav-links">
          <MenuItem
            :key="key"
            v-for="(link, key) of validatedMenu"
            :link="link"
          />
        </div>
      </div>

      <div style="display: flex; align-items: center; gap: 15px">
        <ChatBaseDropDown />
        <UserProfileButton />
      </div>
    </div>

    <div
      class="subnav"
      :class="{ active: validatedMenu && validatedMenu[selectedMenu] }"
    >
      <div
        class="subnav-group"
        v-if="validatedMenu && validatedMenu[selectedMenu]"
      >
        <h2>{{ validatedMenu[selectedMenu].name }}</h2>
        <div class="submenu-links">
          <router-link
            :to="link.link"
            @click="selectedMenu = null"
            :key="key"
            v-for="(link, key) of validatedMenu[selectedMenu].submenu"
            >{{ link.name }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserProfileButton from "./UserProfileButton.vue";
import MenuItem from "./MenuItem.vue";

export default {
  components: {
    UserProfileButton,
    MenuItem,
  },
  data() {
    return {
      selectedMenu: null,
      validatedMenu: [],
      menu: [
        {
          link: "/dispatch",
          name: "Dispatch",
          icon: "fa-solid fa-boxes-stacked",
          permissions: [{ name: "Loads" }],
          exact: true,
        },
        // {
        //   link: "/dispatch/tracking",
        //   name: "Tracking",
        //   icon: "fa-solid fa-boxes-stacked",
        //   permissions: [{ name: "Loads" }],
        //   exact: true,
        // },
        {
          link: "/dispatch/loads-board",
          name: "Load Board",
          icon: "fa-solid fa-boxes-stacked",
          permissions: [{ name: "Loads" }],
          exact: true,
        },
        {
          link: "/salary",
          name: "Payroll",
          icon: "fa-solid fa-dollar-sign",
        },
        {
          link: "/admin",
          name: "Admin",
          icon: "fa-solid fa-unlock",
          permissions: [
            { name: "Roles" },
            { name: "Users" },
            { name: "Carriers" },
            { name: "Invoices" },
            { name: "Performance" },
          ],
          submenu: [
            {
              link: "/admin",
              icon: "fa-solid fa-gauge",
              name: "Business Performance",
              permissions: [{ name: "ADMIN_ONLY" }],
            },
            {
              link: "/admin/roles",
              icon: "fa-solid fa-users-viewfinder",
              name: "Roles",
              permissions: [{ name: "Roles" }],
            },
            {
              link: "/admin/users",
              icon: "fa-solid fa-users",
              name: "Users",
              permissions: [{ name: "Users" }],
            },
            {
              link: "/admin/salary",
              icon: "fa-solid fa-piggy-bank",
              name: "Salary",
              permissions: [{ name: "Users" }],
            },
            {
              link: "/admin/carriers",
              icon: "fa-solid fa-truck-fast",
              name: "Carriers",
              permissions: [{ name: "Carriers" }],
            },
            {
              link: "/admin/invoices",
              icon: "fa-solid fa-file-invoice-dollar",
              name: "Invoices",
              permissions: [{ name: "Invoices" }],
            },
            {
              link: "/admin/referrals",
              icon: "fa-solid fa-star",
              name: "Referrals",
              permissions: [{ name: "ADMIN_ONLY" }],
              hasChildren: true
            },
          ],
        },
      ],
    };
  },
  methods: {
    selectMenuOption(option, key) {
      if (option.submenu && option.submenu.length > 0) {
        this.selectedMenu = key;
      } else {
        this.selectedMenu = null;
      }
    },
    isAuthorized(permissions) {
      let permitted = false;
      for (const item of permissions) {
        let isPermitted = this.user.isAuthorized(item.name, item.action);
        if (isPermitted) permitted = true;
      }
      return permitted;
    },
    validateMenu() {
      this.validatedMenu = this.menu.filter((item) => {
        if (item.submenu) {
          item.submenu = item.submenu.filter((subitem) => {
            if (!subitem.permissions) return true;
            return this.isAuthorized(subitem.permissions);
          });
        }
        if (!item.permissions) return true;
        return this.isAuthorized(item.permissions);
      });
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.validateMenu();
  }
};
</script>

<style lang="scss" scoped>
.user-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: $themeColor1;
  border-bottom: 1px solid $borderColor;
  z-index: 10;
  .content {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    position: relative;
    z-index: 2;
    background: $themeColor1;
  }
  .subnav {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 100%;
    width: 300px;
    height: 100%;
    background: $themeColor1;
    border-left: 1px solid $borderColor;
    padding: 30px;
    opacity: 0;
    transition: ease 0.35s;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
    .subnav-group {
      display: grid;
      row-gap: 50px;
    }
    .submenu-links {
      display: grid;
      row-gap: 12px;
      a {
        text-decoration: none;
        color: $textShade;
        font-size: 14px;
        transition: ease 0.3s;
        &.router-link-exact-active,
        &:hover {
          color: $themeColor2;
        }
      }
    }
  }
}

%nav-link-active {
  background: rgba(0, 0, 0, 0.01);
  color: $themeColor2;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: $themeColor2;
  }
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
